import { useEffect } from 'react'
import { useRequest } from 'ahooks'

import { apiGetFreightTemplateCountryPartition, apiGetRecords } from '../apis'
import { IFreightTemplateDetail, TFreightTemplateFormValues } from '../interface'
import { getRequestCacheOpts } from '../utils'

export const textInputFields = ['templateName']

export const createEmptyWeight = (
  startingWeight: number | null | undefined,
): TFreightTemplateFormValues['templateDetails'][number] => ({
  id: undefined,
  startingWeight,
  endingWeight: undefined,
  externalFreight: undefined,
  externalRegistrationFee: undefined,
  actualFreight: undefined,
  actualRegistrationFee: undefined,
})

export const createInitialFormValues = (
  actionType: 'add' | 'copy' | 'edit' | 'view',
  detail: IFreightTemplateDetail | null,
): TFreightTemplateFormValues => {
  const data: TFreightTemplateFormValues = {
    id: undefined,
    templateName: undefined,
    channelCode: undefined,
    channelEName: undefined,
    channelCName: undefined,
    countryCode: undefined,
    countryPartition: undefined,
    effectiveDate: undefined,
    expirationDate: undefined,
    bubbleCoefficient: undefined,
    carryMethod: 1,
    actualFeeCurrencyType: undefined,
    minimumChargeableWeight: undefined,
    templateDetails: [],
    ...detail,
  }
  if (actionType === 'copy') {
    data.id = undefined
    data.templateDetails = data.templateDetails.map(d => ({ ...d, id: undefined }))
  }
  return data
}

export const useChannelOpts = () => {
  const { data: channelOpts } = useRequest(
    async () => {
      return apiGetRecords({}).then(res => res.map(d => ({ value: d.channelCode, label: d.$channelName, data: d })))
    },
    { ...getRequestCacheOpts() },
  )
  return {
    channelOpts,
  }
}

export const useCountryPartitionOpts = (countryCode: string | undefined, disabled: boolean) => {
  const {
    loading: countryPartitionOptsLoading,
    data: countryPartitionOpts,
    run,
    mutate,
  } = useRequest(
    (countryCode: string) =>
      apiGetFreightTemplateCountryPartition(countryCode).then(res => res.map(d => ({ value: d, label: d }))),
    { manual: true },
  )
  useEffect(() => {
    if (disabled) return
    mutate(undefined)
    if (!countryCode) return
    run(countryCode)
  }, [countryCode, disabled, mutate, run])
  return {
    countryPartitionOptsLoading,
    countryPartitionOpts,
    countryPartitionRequired: countryPartitionOptsLoading || !!countryPartitionOpts?.length,
  }
}
