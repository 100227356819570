import { IDetailProps, useTitle } from '../utils'

export default function BKZXR({ isZh, onTitle }: IDetailProps) {
  useTitle(isZh ? '云途全球专线挂号（标快） - CN' : '云途全球专线挂号（标快） - EN', onTitle)
  return isZh ? <CN /> : <EN />
}

/** 中文 */
function CN() {
  return <div>CN...</div>
}

/** 英文 */
function EN() {
  return <div>EN...</div>
}
