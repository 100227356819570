import axios, { AxiosRequestConfig } from 'axios'

import { getGlobalState } from '@/globalStore/globalStore'
import { ISuccessAndFailResultModalProps } from '../components/SuccessAndFailResultModal'
import {
  IFreightTemplateDetail,
  IFreightTemplateRecord,
  IFreightTemplateReqData,
  IFreightTemplateRes,
  IRecord,
  IReqData,
  IShopShippingChannelRecord,
  IShopShippingItem,
} from './interface'
import './__mock__'

/** 获取物流渠道列表 */
export const apiGetRecords = async (params: IReqData, signal?: AxiosRequestConfig['signal']): Promise<IRecord[]> => {
  return axios.get('/linkhub_oms/logistic/list', { params, signal }).then(({ $data }) => {
    const isZh = getGlobalState().lang === 'zh'
    return ($data || []).map(
      (d: IRecord) =>
        <IRecord>{
          ...d,
          $channelName: isZh ? d.channelCName : d.channelEName,
          $supportRegion: isZh ? d.supportCRegion : d.supportERegion,
          $movingAttribute: isZh ? d.movingCAttribute : d.movingEAttribute,
        },
    )
  })
}

/** 获取物流设置列表 */
export const apiGetShopShippingRecords = async (): Promise<IShopShippingItem[]> => {
  return axios.get('/linkhub_oms/logistic/query_channel_priority').then(res => {
    const isZh = getGlobalState().lang === 'zh'
    type Any = any
    return (res.$data || []).map(
      ({ sysShopId, shopName, shippingNameAndChannelPriorityParamList }: Any): IShopShippingItem => ({
        sysShopId,
        shopName,
        children: (shippingNameAndChannelPriorityParamList || []).map(
          ({ shippingName, channelPriority }: Any): IShopShippingItem['children'][number] => ({
            shippingName,
            children: (channelPriority || [])
              .concat()
              .sort((a: Any, b: Any) => a.priority - b.priority)
              .map(
                ({ channelCode, channelCName, channelEName }: Any): IShopShippingChannelRecord => ({
                  sysShopId,
                  shopName,
                  shippingName,
                  channelCode,
                  $channelName: isZh ? channelCName : channelEName,
                  channelCName,
                  channelEName,
                }),
              ),
          }),
        ),
      }),
    )
  })
}

/** 物流渠道变更（添加/删除/排序） */
export const apiChangeShopShipping = async (
  reqData: Pick<IShopShippingChannelRecord, 'sysShopId' | 'shippingName'> & {
    channelCodeList: string[]
  },
) => {
  await axios.post('/linkhub_oms/logistic/edit_channel_priority', reqData)
}

/** 物流渠道启用/停用 */
export const apiChangeChannelState = async (
  reqData: Pick<IRecord, 'channelCode' | 'channelState'> & {
    /** 开始停用时间，YYYY-MM-DD，停用时必传 */
    startDate?: string
    /** 结束停用时间，YYYY-MM-DD */
    endDate?: string
  },
) => {
  await axios.put('/linkhub_oms/logistic/edit_status', reqData)
}

/** 获取运费模版列表 */
export const apiGetFreightTemplates = async (
  { $channelName, ...params }: IFreightTemplateReqData,
  signal?: AxiosRequestConfig['signal'],
) => {
  const key = getGlobalState().lang === 'zh' ? 'channelCName' : 'channelEName'
  return axios
    .get(`/linkhub_oms/logistic_freight/template`, {
      params: {
        ...params,
        [key]: $channelName,
      },
      signal,
    })
    .then<IFreightTemplateRes>(({ $data }) => ({
      ...$data,
      records: $data.records.map((record: IFreightTemplateRecord) => ({
        ...record,
        $channelName: record[key] ?? '',
      })),
    }))
}

/** 获取运费模板详情 */
export const apiGetFreightTemplateDetail = async (id: number): Promise<IFreightTemplateDetail> => {
  return axios.get(`/linkhub_oms/logistic_freight/template/${id}`).then(res => res.$data)
}

/** 新增运费模板 */
export const apiAddFreightTemplate = async (reqData: IFreightTemplateDetail<false>) => {
  await axios.post(`/linkhub_oms/logistic_freight/template`, reqData)
}

/** 编辑运费模板 */
export const apiEditFreightTemplate = async (reqData: IFreightTemplateDetail<true>) => {
  await axios.put(`/linkhub_oms/logistic_freight/template`, reqData)
}

/** 删除运费模板 */
export const apiDeleteFreightTemplate = async (id: number) => {
  await axios.delete(`/linkhub_oms/logistic_freight/template/${id}`)
}

/** 获取国家分区 */
export const apiGetFreightTemplateCountryPartition = async (countryCode: string): Promise<string[]> => {
  return axios
    .get(`/linkhub_oms/logistic_freight/template/country_partition`, { params: { countryCode } })
    .then(res => (res.$data || []).filter(Boolean))
}

/** Excel导入运费模板（仅商家侧） */
export const apiImportFreightTemplates = async (file: File): Promise<ISuccessAndFailResultModalProps['result']> => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post(`/linkhub_oms/logistic_freight/template/excel`, formData).then(res => res.$data)
}
