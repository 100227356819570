import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMemoizedFn, useRequest } from 'ahooks'
import { produce } from 'immer'
import dayjs from 'dayjs'
import { Button, Input, message, Modal, Popover, Select, Space } from 'antd'
import type { ColumnType } from 'antd/es/table'
import { DownloadOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { CusTable, TCusTableIns } from '@/components/CusTable'
import { SPIN_DELAY } from '@/components/Loading'
import { useControllerRef } from '@/hooks/useAbortController'
import { filePick } from '@/utils/filePick'
import { DEFAULT_PAGE_SIZE, getDefaultPaginationProps } from '@/utils/pagination'
import { fileValidate, selectFilterOption } from '@/utils/utils'
import { SuccessAndFailResultModal } from '../../components/SuccessAndFailResultModal'
import { useCOUNTRY_NAME_MAP, useCOUNTRY_OPTS } from '../../consts/country'
import { apiDeleteFreightTemplate, apiGetFreightTemplates, apiImportFreightTemplates } from '../apis'
import { IFreightTemplateRecord, IFreightTemplateReqData, IFreightTemplateRes } from '../interface'
import { popupSlot } from '../utils'
import { DetailDrawer } from './DetailDrawer'
import styles from '../styles.module.less'

/** 运费模板（仅员工侧） */
export const FreightTemplate = () => {
  const { t } = useTranslation()
  const [countryCode, setCountryCode] = useState<IFreightTemplateReqData['countryCode']>()
  const [$channelName, set$channelName] = useState<IFreightTemplateReqData['$channelName']>()
  const [templateName, setTemplateName] = useState<IFreightTemplateReqData['templateName']>()
  const [page, setPage] = useState<IFreightTemplateReqData['page']>(1)
  const [size, setSize] = useState<IFreightTemplateReqData['size']>(DEFAULT_PAGE_SIZE)

  const countryNameMap = useCOUNTRY_NAME_MAP()
  const countryOpts = useCOUNTRY_OPTS()

  const reqData: IFreightTemplateReqData = { countryCode, $channelName, templateName, page, size }
  const tableRef = useRef<TCusTableIns>(null)
  const controllerRef = useControllerRef()
  const {
    loading,
    data,
    params: [param],
    run: getList,
    refresh,
    mutate,
  } = useRequest<IFreightTemplateRes, [IFreightTemplateReqData]>(
    async req => {
      controllerRef.current.abort()
      controllerRef.current = new AbortController()
      return apiGetFreightTemplates(req, controllerRef.current.signal)
    },
    {
      defaultParams: [reqData],
      onBefore: () => CusTable.scrollToTop(tableRef.current),
    },
  )

  const deleteRecord = useMemoizedFn((id: number) => {
    mutate(oldData => {
      if (!oldData) return oldData
      return produce(oldData, draft => {
        let index = -1
        for (const item of draft.records) {
          index++
          if (item.id === id) {
            draft.records.splice(index, 1)
            return
          }
        }
      })
    })
  })

  const columns = useMemo<ColumnType<IFreightTemplateRecord>[]>(() => {
    return [
      {
        title: t('Logistics.yun-fei-mo-ban'),
        render: (_v, { id, templateName }) => (
          <a onClick={() => DetailDrawer.open({ actionType: 'view', id })}>{templateName}</a>
        ),
      },
      {
        title: t('Logistics.wu-liu-qu-dao'),
        render: (_v, { $channelName: v }) => v,
      },
      {
        title: t('Logistics.guo-jia'),
        render: (_v, { countryCode: v }) => countryNameMap[v] ?? v,
      },
      {
        title: t('Logistics.guo-jia-fen-qu'),
        render: (_v, { countryPartition: v }) => v,
      },
      {
        title: t('Logistics.mo-ban-you-xiao-qi'),
        width: 245,
        render: (_v, { effectiveDate: v1, expirationDate: v2 }) =>
          `${dayjs(v1).format($F_YMDHm)} ~ ${dayjs(v2).format($F_YMDHm)}`,
      },
      {
        title: t('2-common.cao-zuo'),
        width: 160,
        render: (_v, record) => {
          return (
            <>
              <Button
                type="link"
                onClick={() =>
                  DetailDrawer.open({
                    actionType: 'edit',
                    id: record.id,
                    onSuccess: refresh,
                  })
                }
              >
                {t('2-common.bian-ji')}
              </Button>
              <Button
                type="link"
                onClick={() =>
                  DetailDrawer.open({
                    actionType: 'copy',
                    id: record.id,
                    onSuccess: refresh,
                  })
                }
              >
                {t('2-common.fu-zhi')}
              </Button>
              <Button
                type="link"
                onClick={() =>
                  Modal.confirm({
                    title: t('2-common.que-ren-yao-shan-chu-ma'),
                    content: (
                      <>
                        {record.templateName}
                        <br />
                        {record.$channelName}
                      </>
                    ),
                    onOk: async () => {
                      await apiDeleteFreightTemplate(record.id)
                      message.success(t('2-common.cao-zuo-cheng-gong'))
                      deleteRecord(record.id)
                    },
                  })
                }
              >
                {t('2-common.shan-chu')}
              </Button>
            </>
          )
        },
      },
    ]
  }, [countryNameMap, deleteRecord, refresh, t])

  const handleImport = () => {
    filePick({
      accept: '.xlsx',
      onFilePick: ([file]) => {
        const msg = fileValidate(file, { accept: '.xlsx', max: 10 })
        if (msg) return void message.error(msg)
        Modal.confirm({
          title: t('Transaction.que-ren-yao-dao-ru-ma'),
          content: file.name,
          onOk: async () => {
            const result = await apiImportFreightTemplates(file)
            SuccessAndFailResultModal.open({
              result,
              onClose: () => {
                if (result.successTotal > 0) {
                  setPage(1)
                  getList({ ...reqData, page: 1 })
                }
              },
            })
          },
        })
      },
    })
  }

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <div className={styles.header_filter}>
          <Space size={12}>
            <Select
              style={{ width: 190 }}
              placeholder={t('Logistics.qing-xuan-ze-guo-jia')}
              allowClear
              showSearch
              filterOption={selectFilterOption}
              options={countryOpts}
              onChange={val => {
                setCountryCode(val)
                setPage(1)
                getList({ ...reqData, countryCode: val, page: 1 })
              }}
            />
            <Input.Search
              style={{ width: 270 }}
              placeholder={t('Logistics.qing-shu-ru-yun-fei-mo-ban')}
              enterButton
              allowClear
              onChange={e => setTemplateName(e.target.value.trim())}
              onSearch={(val, _e, info) => {
                val = val.trim()
                if (info?.source === 'clear' && !param?.templateName) return
                setPage(1)
                getList({ ...reqData, templateName: val, page: 1 })
              }}
            />
            <Input.Search
              style={{ width: 270 }}
              placeholder={t('Logistics.qing-shu-ru-wu-liu-qu-dao')}
              enterButton
              allowClear
              onChange={e => set$channelName(e.target.value.trim())}
              onSearch={(val, _e, info) => {
                val = val.trim()
                if (info?.source === 'clear' && !param?.$channelName) return
                setPage(1)
                getList({ ...reqData, $channelName: val, page: 1 })
              }}
            />
          </Space>
        </div>
        <div className={styles.header_action}>
          <Space size={12}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() =>
                DetailDrawer.open({
                  actionType: 'add',
                  id: null,
                  onSuccess: refresh,
                })
              }
            >
              {t('2-common.xin-zeng')}
            </Button>
            <Popover
              mouseLeaveDelay={0.2}
              placement="topRight"
              content={
                <Button
                  type="link"
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    window.open(`https://shipo-erp.oss-us-east-1.aliyuncs.com/excelTemplate/freight_template.xlsx`)
                  }}
                >
                  {t('2-common.excel-mo-ban-xia-zai')}
                </Button>
              }
            >
              <Button type="primary" onClick={handleImport}>
                {t('2-common.excel-dao-ru')}
                <InfoCircleOutlined />
              </Button>
            </Popover>
          </Space>
        </div>
      </div>

      <div className={styles.table}>
        <CusTable
          rowKey="id"
          loading={{ spinning: loading, delay: SPIN_DELAY }}
          dataSource={data?.records}
          columns={columns}
          ref={tableRef}
          verticalFlexLayout
          pagination={{
            ...getDefaultPaginationProps(data?.total || 0),
            current: page,
            pageSize: size,
            onChange: (page, size) => {
              setPage(page)
              setSize(size)
              getList({ ...reqData, page, size })
            },
          }}
        />
      </div>

      <popupSlot.Slot />
    </div>
  )
}
