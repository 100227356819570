import { ChangeEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import _ from 'lodash'
import { Button, Input, Modal, Spin } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import { CusTable } from '@/components/CusTable'
import { SPIN_DELAY } from '@/components/Loading'
import { useControllerRef } from '@/hooks/useAbortController'
import { apiGetRecords } from '../apis'
import { IRecord } from '../interface'
import { getRequestCacheOpts, listFilter, popupSlot } from '../utils'
import { ChannelName, ChannelState, SupportRegion } from './Cells'

interface IAddChannelModalProps {
  addedChannelCodes: string[]
  onAdd: (info: IRecord) => void | Promise<void>
}

const updatePopup = popupSlot.insert(null)

/** 添加物流渠道弹窗 */
export const AddChannelModal = Object.assign(
  ({ addedChannelCodes, onAdd, destroy }: IAddChannelModalProps & { destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)
    const [loading, setLoading] = useState(false)
    const [keyWord, setKeyword] = useState('')

    const controllerRef = useControllerRef()
    const {
      loading: fetching,
      data,
      mutate,
    } = useRequest<IRecord[], []>(
      async () => {
        controllerRef.current.abort()
        controllerRef.current = new AbortController()
        return apiGetRecords({}, controllerRef.current.signal)
      },
      {
        ...getRequestCacheOpts(),
        onError: () => void mutate(undefined),
      },
    )

    const tableRecords = useMemo<IRecord[]>(() => {
      if (!data) return []
      let filterData = data
      if (keyWord) {
        filterData = data.filter(d => listFilter(d, keyWord))
      }
      return filterData
    }, [data, keyWord])

    const debounceHandleSearchChange = useMemo(
      () => _.debounce((e: ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value.trim()), 300),
      [],
    )

    return (
      <Modal
        title={t('Logistics.tian-jia-wu-liu-qu-dao')}
        width={850}
        cancelText={t('2-common.guan-bi')}
        okButtonProps={{ style: { display: 'none' } }}
        open={open}
        onCancel={onHide}
        afterOpenChange={afterOpenChange}
      >
        <Spin spinning={loading}>
          <div style={{ width: 300, marginTop: 12, marginBottom: 12 }}>
            <Input
              prefix={<SearchOutlined />}
              placeholder={t('Logistics.qing-shu-ru-wu-liu-ming-cheng-wu-liu-qu-dao')}
              allowClear
              onChange={debounceHandleSearchChange}
            />
          </div>
          <CusTable
            rowKey="channelCode"
            loading={{ spinning: !data && fetching, delay: SPIN_DELAY }}
            dataSource={tableRecords}
            columns={[
              {
                title: t('Logistics.wu-liu-ming-cheng'),
                render: (_v, { logisticsName: v }) => v,
              },
              {
                title: t('Logistics.wu-liu-qu-dao'),
                render: (_v, record) => <ChannelName record={record} />,
              },
              {
                title: t('Logistics.can-kao-shi-xiao'),
                render: (_v, record) => {
                  if (!('channelCode' in record)) return
                  return record.referenceAging
                },
              },
              {
                title: t('Logistics.zhi-chi-qu-yu'),
                render: (_v, record) => <SupportRegion record={record} />,
              },
              {
                title: t('Logistics.qi-yong-zhuang-tai'),
                width: 100,
                render: (_v, record) => <ChannelState record={record} />,
              },
              {
                title: t('2-common.cao-zuo'),
                width: 100,
                render: (_v, record) => {
                  const added = !!addedChannelCodes.includes(record.channelCode)
                  return (
                    <Button
                      type="link"
                      disabled={added}
                      onClick={async () => {
                        setLoading(true)
                        try {
                          await onAdd(record)
                          onHide()
                        } catch (err) {
                          setLoading(false)
                          throw err
                        }
                      }}
                    >
                      {added ? t('2-common.yi-tian-jia') : t('2-common.tian-jia')}
                    </Button>
                  )
                },
              },
            ]}
          />
        </Spin>
      </Modal>
    )
  },
  {
    open: (props: IAddChannelModalProps) => {
      updatePopup(<AddChannelModal {...props} destroy={() => updatePopup(null)} />)
    },
  },
)
