import { IDetailProps, useTitle } from '../utils'

export default function ZDZXR({ isZh, onTitle }: IDetailProps) {
  useTitle(isZh ? '中东专线 - CN' : '中东专线 - EN', onTitle)
  return isZh ? <CN /> : <EN />
}

/** 中文 */
function CN() {
  return <div>CN...</div>
}

/** 英文 */
function EN() {
  return <div>EN...</div>
}
