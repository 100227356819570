import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { Form, message, Modal } from 'antd'

import { FormItemDatePicker } from '@/components/FormItemDateTime'
import { useFormAllValues } from '@/hooks/hooks'
import { apiChangeChannelState } from '../apis'
import { popupSlot } from '../utils'

interface IStopSettingModalProps {
  channelCode: string
  channelName: string
  onSuccess?: () => void
}

type TFormValues = { startDate: string; endDate: string }

const updatePopup = popupSlot.insert(null)

/** 停用物流设置 */
export const StopSettingModal = Object.assign(
  ({ channelCode, channelName, onSuccess, destroy }: IStopSettingModalProps & { destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)
    const [loading, setLoading] = useState(false)

    const initialFormValues = useMemo<TFormValues>(() => ({ startDate: dayjs().format($F_YMD), endDate: '' }), [])
    const [form] = Form.useForm<TFormValues>()
    const allValues = useFormAllValues(form, initialFormValues)

    return (
      <Modal
        title={t('Logistics.ting-yong-wu-liu')}
        width={460}
        okButtonProps={{ loading }}
        onOk={async () => {
          let values = await form.validateFields()
          values = form.getFieldsValue(true)
          try {
            setLoading(true)
            await apiChangeChannelState({
              channelCode,
              channelState: 0,
              startDate: values.startDate,
              endDate: values.endDate || undefined,
            })
            message.success(t('2-common.cao-zuo-cheng-gong'))
            onHide()
            onSuccess?.()
          } finally {
            setLoading(false)
          }
        }}
        open={open}
        onCancel={onHide}
        afterOpenChange={afterOpenChange}
      >
        <Form form={form} labelCol={{ span: 7 }} initialValues={initialFormValues}>
          <Form.Item wrapperCol={{ span: 17 }} label={t('Logistics.wu-liu-qu-dao')}>
            {channelName}
          </Form.Item>
          <Form.Item label={t('Logistics.kai-shi-ting-yong-shi-jian')} name="startDate" rules={[{ required: true }]}>
            <FormItemDatePicker
              style={{ width: '100%' }}
              allowClear={false}
              disabledDate={d => d.endOf('day') < dayjs().endOf('day')}
              onChange={val => {
                const { endDate } = allValues
                if (val && endDate && dayjs(val).endOf('day') >= dayjs(endDate).endOf('day')) {
                  form.setFieldValue('endDate', '')
                }
              }}
            />
          </Form.Item>
          <Form.Item label={t('Logistics.jie-shu-ting-yong-shi-jian')} name="endDate">
            <FormItemDatePicker
              style={{ width: '100%' }}
              disabledDate={d => {
                const { startDate } = allValues
                if (startDate) {
                  return d.endOf('day') <= dayjs(startDate).endOf('day')
                }
                return d.endOf('day') <= dayjs().endOf('day')
              }}
              defaultPickerValue={allValues.startDate ? dayjs(allValues.startDate).add(1, 'day') : undefined}
              showNow={false}
            />
          </Form.Item>
        </Form>
      </Modal>
    )
  },
  {
    open: (props: IStopSettingModalProps) => {
      updatePopup(<StopSettingModal {...props} destroy={() => updatePopup(null)} />)
    },
  },
)
