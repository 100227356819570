import { createPopupSlot } from '@/services/popupSlot'
import { IRecord } from './interface'

export const popupSlot = createPopupSlot()

/** useRequest 缓存配置 */
export const getRequestCacheOpts = (channelState?: number | null) => ({
  cacheKey: `Logistics/list/${channelState ?? ''}` as const,
  cacheTime: -1 as const,
})

/** 列表过滤 */
export const listFilter = (data: IRecord, keyword: string): boolean => {
  const name1 = `${data.$channelName ?? ''}`
  const name2 = `${data.logisticsName ?? ''}`
  return name1.includes(keyword) || name2.includes(keyword)
}
