import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import _ from 'lodash'
import { Button, Input, message, Modal, Radio, Space } from 'antd'
import type { ColumnType } from 'antd/es/table'
import { SearchOutlined, SettingOutlined } from '@ant-design/icons'

import { CusTable, TCusTableIns } from '@/components/CusTable'
import { SPIN_DELAY } from '@/components/Loading'
import { useControllerRef } from '@/hooks/useAbortController'
import { apiChangeChannelState, apiGetRecords } from '../apis'
import { IRecord, IReqData, TTableRecord } from '../interface'
import { getRequestCacheOpts, listFilter, popupSlot } from '../utils'
import { ChannelName, ChannelState, SupportRegion } from './Cells'
import { ShopShippingModal } from './ShopShippingModal'
import { StopSettingModal } from './StopSettingModal'
import styles from '../styles.module.less'

export const List = ({ isEmployee = false }: { isEmployee?: boolean }) => {
  const { t } = useTranslation()
  const [channelState, setChannelState] = useState<IReqData['channelState']>()
  const [keyWord, setKeyword] = useState('')
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])

  const reqData: IReqData = { channelState }
  const tableRef = useRef<TCusTableIns>(null)
  const controllerRef = useControllerRef()
  const {
    loading: fetching,
    data,
    run: getList,
    refresh,
    mutate,
  } = useRequest<IRecord[], [IReqData]>(
    async req => {
      controllerRef.current.abort()
      controllerRef.current = new AbortController()
      return apiGetRecords(req, controllerRef.current.signal)
    },
    {
      ...getRequestCacheOpts(channelState),
      defaultParams: [reqData],
      onBefore: () => CusTable.scrollToTop(tableRef.current),
      onError: () => void mutate(undefined),
    },
  )

  const tableRecords = useMemo<TTableRecord[]>(() => {
    if (!data) return []
    let filterData = data
    if (keyWord) {
      filterData = data.filter(d => listFilter(d, keyWord))
    }
    const logisticsMap = new Map<string, IRecord[]>()
    filterData.forEach(d => {
      const key = d.logisticsName
      logisticsMap.set(key, (logisticsMap.get(key) || []).concat(d))
    })
    const list: TTableRecord[] = []
    logisticsMap.forEach((v, k) => {
      list.push({
        logisticsName: k,
        children: v,
      })
    })
    return list
  }, [data, keyWord])

  const columns = useMemo<ColumnType<TTableRecord>[]>(() => {
    return [
      {
        title: t('Logistics.wu-liu-ming-cheng'),
        render: (_v, record) => {
          if (!('channelCode' in record)) return record.logisticsName
        },
      },
      {
        title: t('Logistics.wu-liu-qu-dao'),
        render: (_v, record) => {
          if (!('channelCode' in record)) return
          return <ChannelName record={record} />
        },
      },
      {
        title: t('Logistics.can-kao-shi-xiao'),
        render: (_v, record) => {
          if (!('channelCode' in record)) return
          return record.referenceAging
        },
      },
      {
        title: t('Logistics.zhi-chi-qu-yu'),
        render: (_v, record) => {
          if (!('channelCode' in record)) return
          return <SupportRegion record={record} />
        },
      },
      {
        title: t('Logistics.zou-huo-shu-xing'),
        render: (_v, record) => {
          if (!('channelCode' in record)) return
          return record.$movingAttribute
        },
      },
      {
        title: t('Logistics.qi-yong-zhuang-tai'),
        width: 130,
        render: (_v, record) => {
          if (!('channelCode' in record)) return
          return <ChannelState record={record} />
        },
      },
      {
        title: t('2-common.cao-zuo'),
        hidden: !isEmployee,
        width: 110,
        render: (_v, record) => {
          if (!('channelCode' in record)) return
          if (record.channelState === 0)
            return (
              <Button
                type="link"
                onClick={() => {
                  Modal.confirm({
                    title: t('Logistics.que-ren-yao-qi-yong-ma'),
                    content: record.$channelName,
                    onOk: async () => {
                      await apiChangeChannelState({
                        channelCode: record.channelCode,
                        channelState: 1,
                      })
                      message.success(t('2-common.cao-zuo-cheng-gong'))
                      refresh()
                    },
                  })
                }}
              >
                {t('Logistics.qi-yong')}
              </Button>
            )
          if (record.channelState === 1)
            return (
              <Button
                type="link"
                danger
                onClick={() =>
                  StopSettingModal.open({
                    channelCode: record.channelCode,
                    channelName: record.$channelName,
                    onSuccess: () => refresh(),
                  })
                }
              >
                {t('Logistics.ting-yong')}
              </Button>
            )
        },
      },
    ]
  }, [isEmployee, refresh, t])

  const debounceHandleSearchChange = useMemo(
    () => _.debounce((e: ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value.trim()), 300),
    [],
  )

  useEffect(() => {
    setExpandedRowKeys(tableRecords.map(d => d.logisticsName))
  }, [tableRecords])

  return (
    <div className={styles.box}>
      <div className={styles.header}>
        <div className={styles.header_filter}>
          <Space size={12}>
            <Radio.Group
              buttonStyle="solid"
              value={channelState}
              onChange={e => {
                const val = e.target.value
                setChannelState(val)
                getList({ ...reqData, channelState: val })
              }}
            >
              <Radio.Button value={undefined}>{t('2-common.quan-bu')}</Radio.Button>
              <Radio.Button value={1}>{t('Logistics.qi-yong-zhong')}</Radio.Button>
              <Radio.Button value={0}>{t('Logistics.ting-yong-zhong')}</Radio.Button>
            </Radio.Group>
            <Input
              style={{ width: 300 }}
              prefix={<SearchOutlined />}
              placeholder={t('Logistics.qing-shu-ru-wu-liu-ming-cheng-wu-liu-qu-dao')}
              allowClear
              onChange={debounceHandleSearchChange}
            />
          </Space>
        </div>
        <div className={styles.header_action}>
          {!isEmployee && (
            <Button type="primary" icon={<SettingOutlined />} onClick={() => ShopShippingModal.open()}>
              {t('Logistics.wu-liu-she-zhi')}
            </Button>
          )}
        </div>
      </div>

      <div className={styles.table}>
        <CusTable
          rowKey={record => {
            if ('channelCode' in record) return record.channelCode
            else return record.logisticsName
          }}
          loading={{ spinning: fetching, delay: SPIN_DELAY }}
          dataSource={tableRecords}
          columns={columns}
          expandable={{
            expandRowByClick: true,
            expandedRowKeys,
            onExpandedRowsChange: keys => setExpandedRowKeys(keys as string[]),
          }}
          rowClassName={(_record, _index, indent) => {
            if (indent === 0) return styles.expandRow
            return ''
          }}
          ref={tableRef}
          verticalFlexLayout
        />
      </div>

      <popupSlot.Slot />
    </div>
  )
}
