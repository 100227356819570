import { t } from 'i18next'
import { Button, Col, Form, FormInstance, InputNumber, Popconfirm, Row } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import { CusTable } from '@/components/CusTable'
import { TFreightTemplateFormValues } from '../interface'
import { createEmptyWeight } from './utils'

export const templateDetailsRender = (
  form: FormInstance<TFreightTemplateFormValues>,
  allValues: TFreightTemplateFormValues,
  disabledBtn: boolean,
) => {
  const rowIdPrefix = 'FreightTemplate_Dedail_templateDetails_'
  const list = allValues.templateDetails
  const validateAllWeight = () => {
    list.forEach((_d, i) => {
      form.validateFields([
        ['templateDetails', i, 'startingWeight'],
        ['templateDetails', i, 'endingWeight'],
      ])
    })
  }
  return (
    <Form.List name="templateDetails">
      {(fields, { add, remove }) => (
        <CusTable
          locale={{ emptyText: <div style={{ padding: 6 }}>{t('2-common.zan-wu-shu-ju')}</div> }}
          rowKey={r => r.name}
          dataSource={fields}
          onRow={(_d, i) => ({ id: `${rowIdPrefix}${i}` })}
          columns={[
            {
              title: t('Logistics.zhong-liang-fan-wei'),
              width: 100,
              render: () => t('Logistics.zhong-liang-fan-wei'),
            },
            {
              title: t('Logistics.kai-shi-zhong-liang-g'),
              width: 130,
              render: (_v, _r, index) => {
                return (
                  <Form.Item
                    noStyle
                    name={[index, 'startingWeight']}
                    rules={[
                      { required: true },
                      {
                        validator: async (_r, v) => {
                          if (v == null) return
                          const before = list
                            .slice(0, index)
                            .map(d => [d.startingWeight, d.endingWeight])
                            .flat()
                          if (before.filter(Boolean).some(d => (d || 0) > v)) throw new Error()
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      precision={2}
                      step={10}
                      onChange={validateAllWeight}
                    />
                  </Form.Item>
                )
              },
            },
            {
              title: t('Logistics.jie-zhi-zhong-liang-g'),
              width: 130,
              render: (_v, _r, index) => {
                return (
                  <Form.Item
                    noStyle
                    name={[index, 'endingWeight']}
                    rules={[
                      { required: true },
                      {
                        validator: async (_r, v) => {
                          if (v == null) return
                          const start = list[index].startingWeight
                          if (start && start >= v) throw new Error()
                          const before = list
                            .slice(0, index)
                            .map(d => [d.startingWeight, d.endingWeight])
                            .flat()
                          if (before.filter(Boolean).some(d => (d || 0) >= v)) throw new Error()
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      min={0}
                      precision={2}
                      step={10}
                      onChange={validateAllWeight}
                    />
                  </Form.Item>
                )
              },
            },
            {
              title: allValues.countryPartition || t('Logistics.fen-qu'),
              align: 'center',
              render: (_v, _r, index) => {
                const cProps = {
                  style: { margin: 0 },
                  layout: 'horizontal' as const,
                  required: false,
                  rules: [{ required: true, message: '' }],
                }
                return (
                  <Row gutter={[12, 6]}>
                    <Col span={12}>
                      <Form.Item {...cProps} label={t('Logistics.dui-wai-yun-fei')} name={[index, 'externalFreight']}>
                        <InputNumber style={{ width: '100%' }} min={0} precision={2} addonAfter="USD" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item {...cProps} label={t('Logistics.shi-ji-yun-fei')} name={[index, 'actualFreight']}>
                        <InputNumber style={{ width: '100%' }} min={0} precision={2} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        {...cProps}
                        label={t('Logistics.dui-wai-gua-hao-fei')}
                        name={[index, 'externalRegistrationFee']}
                      >
                        <InputNumber style={{ width: '100%' }} min={0} precision={2} addonAfter="USD" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        {...cProps}
                        label={t('Logistics.shi-ji-gua-hao-fei')}
                        name={[index, 'actualRegistrationFee']}
                      >
                        <InputNumber style={{ width: '100%' }} min={0} precision={2} />
                      </Form.Item>
                    </Col>
                  </Row>
                )
              },
            },
            {
              title: (
                <Button
                  size="small"
                  type="primary"
                  ghost
                  icon={<PlusOutlined />}
                  disabled={disabledBtn}
                  onClick={() => {
                    add(createEmptyWeight(list.length === 0 ? 0 : list[list.length - 1]?.endingWeight))
                    const lastIndex = list.length
                    setTimeout(() => {
                      const rowId = `${rowIdPrefix}${lastIndex}`
                      document.getElementById(rowId)?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
                    }, 100)
                  }}
                >
                  {t('2-common.tian-jia')}
                </Button>
              ),
              width: 100,
              align: 'center',
              render: (_v, _r, index) => (
                <Popconfirm title={t('2-common.que-ren-yao-shan-chu-ma')} onConfirm={() => remove(index)}>
                  <Button type="link" icon={<DeleteOutlined />} danger disabled={disabledBtn || fields.length === 1} />
                </Popconfirm>
              ),
            },
          ]}
        />
      )}
    </Form.List>
  )
}
