import { IDetailProps, useTitle } from '../utils'

export default function PK0014({ isZh, onTitle }: IDetailProps) {
  useTitle(isZh ? '日本专线挂号 - CN' : '日本专线挂号 - EN', onTitle)
  return isZh ? <CN /> : <EN />
}

/** 中文 */
function CN() {
  return <div>CN...</div>
}

/** 英文 */
function EN() {
  return <div>EN...</div>
}
