import { useTranslation } from 'react-i18next'
import { Badge, Button, Popover } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { IRecord } from '../interface'
import { ChannelDetailModal } from './ChannelDetailModal'

export const ChannelName = ({ record }: { record: Pick<IRecord, 'channelCode' | '$channelName'> }) => {
  return (
    <div style={{ display: 'inline-flex', flexWrap: 'wrap', alignItems: 'center' }}>
      {record.$channelName}
      <Button
        style={{ width: 22 }}
        type="link"
        icon={<QuestionCircleOutlined />}
        onClick={() => ChannelDetailModal.open(record.channelCode)}
      ></Button>
    </div>
  )
}

export const SupportRegion = ({ record }: { record: Pick<IRecord, '$supportRegion'> }) => {
  if (!record.$supportRegion) return
  const max = 20
  const total = record.$supportRegion.length
  if (total <= 20) return record.$supportRegion
  return (
    <Popover overlayStyle={{ maxWidth: 360 }} content={record.$supportRegion}>
      <span>{record.$supportRegion.slice(0, max)}...</span>
    </Popover>
  )
}

export const ChannelState = ({ record }: { record: Pick<IRecord, 'channelState'> }) => {
  const { t } = useTranslation()
  if (record.channelState === 1) return <Badge color="green" text={t('Logistics.qi-yong-zhong')} />
  if (record.channelState === 0) return <Badge color="red" text={t('Logistics.ting-yong-zhong')} />
}
