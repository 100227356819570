import { useEffect } from 'react'
import { useMemoizedFn } from 'ahooks'

export interface IDetailProps {
  isZh: boolean
  onTitle: (title: string) => void
}

export const useTitle = (title: string, onTitle: IDetailProps['onTitle']) => {
  const handleOnTitle = useMemoizedFn(onTitle)
  useEffect(() => {
    handleOnTitle(title)
  }, [handleOnTitle, title])
}
