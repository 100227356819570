import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRequest } from 'ahooks'
import { produce } from 'immer'
import _ from 'lodash'
import { Button, Empty, message, Modal, Popconfirm, Segmented, Space, Spin, Tabs, theme } from 'antd'
import Icon, { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { CusTable } from '@/components/CusTable'
import { Loading } from '@/components/Loading'
import { apiChangeShopShipping, apiGetShopShippingRecords } from '../apis'
import { IShopShippingItem } from '../interface'
import { popupSlot } from '../utils'
import { AddChannelModal } from './AddChannelModal'
import { ChannelName } from './Cells'
import DragSortIcon from '@/assets/icons/dragSort.svg?react'

const updatePopup = popupSlot.insert(null)

/** 物流设置弹窗（仅商家侧） */
export const ShopShippingModal = Object.assign(
  ({ destroy }: { destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)
    const { loading, data, mutate } = useRequest(() => apiGetShopShippingRecords())
    return (
      <Modal
        title={t('Logistics.wu-liu-she-zhi')}
        width={900}
        cancelText={t('2-common.guan-bi')}
        okButtonProps={{ style: { display: 'none' } }}
        open={open}
        onCancel={onHide}
        afterOpenChange={afterOpenChange}
      >
        <div style={{ minHeight: !data ? 200 : undefined }}>
          {!data?.length && loading && <Loading />}
          {!!data?.length && <ShopShipping data={data} mutate={mutate} />}
        </div>
      </Modal>
    )
  },
  {
    open: () => {
      updatePopup(<ShopShippingModal destroy={() => updatePopup(null)} />)
    },
  },
)

function ShopShipping({ data, mutate }: { data: IShopShippingItem[]; mutate: (newData: IShopShippingItem[]) => void }) {
  const { t } = useTranslation()
  const { token } = theme.useToken()
  const [loading, setLoading] = useState(false)
  const [curShopIndex, setCurShopIndex] = useState(0)
  const [curShippingIndex, setCurShippingIndex] = useState(0)
  const curShop = data[curShopIndex] as IShopShippingItem | undefined
  const curShipping = curShop?.children[curShippingIndex]
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 1 } }))

  return (
    <Spin spinning={loading}>
      <Segmented
        options={data.map((d, index) => ({ value: index, label: d.shopName }))}
        value={curShopIndex}
        onChange={setCurShopIndex}
      />
      {!curShop?.children.length || !curShipping ? (
        <Empty />
      ) : (
        <Tabs
          key={curShop.sysShopId}
          style={{ marginTop: 20, height: '100%' }}
          size="small"
          tabPosition="left"
          accessKey={`${curShippingIndex}`}
          onChange={key => setCurShippingIndex(+key)}
          items={curShop.children.map((d, index) => ({
            key: `${index}`,
            label: d.shippingName,
            children: (
              <DndContext
                sensors={sensors}
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={async ({ over, active }) => {
                  if (!over || over.id === active.id) return
                  const oldList = curShipping.children
                  const activeIndex = oldList.findIndex(d => d.channelCode === active.id)
                  const overIndex = oldList.findIndex(d => d.channelCode === over.id)
                  const newList = arrayMove(oldList, activeIndex, overIndex)
                  try {
                    setLoading(true)
                    mutate(
                      produce(data, draft => {
                        draft[curShopIndex].children[curShippingIndex].children = newList
                      }),
                    )
                    await apiChangeShopShipping({
                      sysShopId: newList[0].sysShopId,
                      shippingName: newList[0].shippingName,
                      channelCodeList: newList.map(d => d.channelCode),
                    })
                    message.success(t('2-common.cao-zuo-cheng-gong'))
                  } catch (err) {
                    mutate(
                      produce(data, draft => {
                        draft[curShopIndex].children[curShippingIndex].children = oldList
                      }),
                    )
                    throw err
                  } finally {
                    setLoading(false)
                  }
                }}
              >
                <SortableContext
                  items={curShipping.children.map(d => d.channelCode)}
                  strategy={verticalListSortingStrategy}
                >
                  <CusTable
                    rowKey="channelCode"
                    dataSource={curShipping.children}
                    components={{ body: { row: TableRow } }}
                    columns={[
                      {
                        title: (
                          <>
                            {t('Logistics.wu-liu-qu-dao')}&nbsp;
                            <span style={{ color: token.colorTextLabel, fontWeight: 'normal' }}>
                              {t('Logistics.sort-tip')}
                            </span>
                          </>
                        ),
                        render: (_v, record) => <ChannelName record={record} />,
                      },
                      {
                        title: (
                          <Button
                            size="small"
                            type="primary"
                            ghost
                            icon={<PlusOutlined />}
                            onClick={() => {
                              AddChannelModal.open({
                                addedChannelCodes: curShipping.children.map(d => d.channelCode),
                                onAdd: async info => {
                                  const newList = [...curShipping.children]
                                  newList.unshift({
                                    ..._.pick(info, ['channelCode', '$channelName', 'channelCName', 'channelEName']),
                                    sysShopId: curShop.sysShopId,
                                    shopName: curShop.shopName,
                                    shippingName: curShipping.shippingName,
                                  })
                                  await apiChangeShopShipping({
                                    sysShopId: newList[0].sysShopId,
                                    shippingName: newList[0].shippingName,
                                    channelCodeList: newList.map(d => d.channelCode),
                                  })
                                  message.success(t('2-common.cao-zuo-cheng-gong'))
                                  mutate(
                                    produce(data, draft => {
                                      draft[curShopIndex].children[curShippingIndex].children = newList
                                    }),
                                  )
                                },
                              })
                            }}
                          >
                            {t('2-common.tian-jia')}
                          </Button>
                        ),
                        align: 'center',
                        width: 100,
                        render: (_v, _r, index) => {
                          return (
                            <Space>
                              <Popconfirm
                                title={t('2-common.que-ren-yao-shan-chu-ma')}
                                onConfirm={() => {
                                  const fn = async () => {
                                    const newList = [...curShipping.children]
                                    newList.splice(index, 1)
                                    try {
                                      setLoading(true)
                                      await apiChangeShopShipping({
                                        sysShopId: newList[0].sysShopId,
                                        shippingName: newList[0].shippingName,
                                        channelCodeList: newList.map(d => d.channelCode),
                                      })
                                      message.success(t('2-common.cao-zuo-cheng-gong'))
                                      mutate(
                                        produce(data, draft => {
                                          draft[curShopIndex].children[curShippingIndex].children = newList
                                        }),
                                      )
                                    } finally {
                                      setLoading(false)
                                    }
                                  }
                                  fn()
                                }}
                              >
                                <Button
                                  type="link"
                                  danger
                                  icon={<DeleteOutlined />}
                                  disabled={curShipping.children.length < 2}
                                />
                              </Popconfirm>
                              <Button
                                style={{ pointerEvents: 'none' }}
                                type="link"
                                icon={<Icon component={DragSortIcon} />}
                              />
                            </Space>
                          )
                        },
                      },
                    ]}
                  />
                </SortableContext>
              </DndContext>
            ),
          }))}
        />
      )}
    </Spin>
  )
}

function TableRow(props: any) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  })
  return (
    <tr
      {...props}
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={{
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        cursor: 'move',
        ...(!!isDragging && { position: 'relative', zIndex: 9999 }),
      }}
    />
  )
}
