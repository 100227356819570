import { ComponentType, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Empty, Modal } from 'antd'

import { useGlobalState } from '@/globalStore/globalStore'
import { popupSlot } from '../../utils'
import { IDetailProps } from './utils'

const modules: Record<string, { default: ComponentType<IDetailProps> } | undefined> = import.meta.glob(
  './Details/*.tsx',
  { eager: true },
)

const updatePopup = popupSlot.insert(null)

/** 渠道详情弹窗 */
export const ChannelDetailModal = Object.assign(
  ({ channelCode, destroy }: { channelCode: string; destroy: () => void }) => {
    const { t } = useTranslation()
    const { open, onHide, afterOpenChange } = popupSlot.useAntdPopupAnimation(destroy)

    const isZh = useGlobalState(s => s.lang === 'zh')
    const [title, setTile] = useState('')

    const Detail = useMemo(() => {
      const module = Object.entries(modules).find(([key]) => key.split('/').pop() === `${channelCode}.tsx`)
      return module?.[1]?.default
    }, [channelCode])

    return (
      <Modal
        title={title}
        width={800}
        maskClosable
        keyboard
        cancelText={t('2-common.guan-bi')}
        okButtonProps={{ style: { display: 'none' } }}
        open={open}
        onCancel={onHide}
        afterOpenChange={afterOpenChange}
      >
        {Detail ? <Detail isZh={isZh} onTitle={setTile} /> : <Empty />}
      </Modal>
    )
  },
  {
    open: (channelCode: string) => {
      updatePopup(<ChannelDetailModal channelCode={channelCode} destroy={() => updatePopup(null)} />)
    },
  },
)
